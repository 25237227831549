<template>
  <view-base ref="base" class="auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Login-->
      <b-col
        class="d-flex align-items-center px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >

          <!-- form -->
          <validation-observer ref="loginForm" #default="{invalid}">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('auth.email') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button class="mt-3" type="submit" variant="primary" block :disabled="invalid">
                {{ $t('auth.signin') }}
              </b-button>
              <span v-if="error != ''" class="text-danger">{{ error }}</span>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </view-base>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ViewBase from '@/views/ViewBase.vue'
import { getUserData } from '@/auth/utils'

import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    ViewBase,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      userEmail: '',
      error: '',

      // validation rules
      required,
      email,
    }
  },
  mounted() {
    if (!(getUserData().email === 'danilo@eatz.pt' || getUserData().email === 'gestor@eatz.pt')) {
      this.$router.push({ name: 'not-authorized' })
    }
  },
  methods: {
    login() {
      this.$refs.base.showLoading()
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.spyLogin({
            email: this.userEmail,
          })
          .then(response => {
            useJwt.setToken(response.data.access_token)
            window.$cookies.set('userData', JSON.stringify(response.data.userData))
            window.location = `${window.location.origin}`
          })
          .catch(error => {
            console.log('error')
            this.$refs.base.hideLoading()
            this.setError(error.response.data.message ?? 'Ocorreu problemas no seu request, tente novamente mais tarde')
          })
        }
      })
    },
    setError(msg) {
      this.error = this.$t(msg)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
